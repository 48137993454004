@use 'sass:map';
@import 'src/assets/mixins';
@import 'src/assets/typography';
@import 'src/assets/elevation';
@import 'src/assets/colors';

.contact-dialog,
.gallery-dialog,
.filter-dialog {
  max-width: unset !important;
  width: 100%;
  height: calc(100% - 70px);
  align-self: end;
  @include elevation(3);

  @include mq(medium) {
    max-width: 80vw !important;
    width: 780px;
    height: auto;
    max-height: 696px;
    align-self: unset;
  }

  .mdc-dialog__surface {
    border-radius: 0 !important;

    @include mq(medium) {
      border-radius: var(--mdc-dialog-container-shape, 4px) !important;
    }
  }

  .mdc-dialog__title {
    display: flex;
    height: 85px;
    padding: 0 16px;
    align-items: center;

    @include mq(medium) {
      border-bottom: 1px solid map-get($light, other, divider);
    }

    span {
      @include typography(h5, $important: true);
      color: map-get($light, text, primary) !important;
    }
    mat-icon {
      cursor: pointer;
      margin-left: auto;
      color: map-get($light, primary, main) !important;
    }
  }

  .mdc-dialog__content {
    padding: 0 16px !important;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: unset;

    @include mq(medium) {
      padding: 32px 24px !important;
      flex-direction: row;
      justify-content: center;
      max-height: 445px;
    }

    .left-side {
      flex-basis: 33%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: map-get($light, text, primary);
      gap: 12px;

      .phone {
        a {
          color: map-get($light, text, primary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .mdc-dialog__actions {
    justify-content: space-between;
    padding: 20px 16px;
    border-top: 1px solid map-get($light, other, divider);

    &.desktop-actions {
      display: none;
      @include mq(medium) {
        display: flex;
      }
    }
    &.mobile-actions {
      app-button {
        display: flex;
        flex-grow: 1;
      }
      button {
        flex-grow: 1;
      }
      @include mq(medium) {
        display: none;
      }
    }
  }

  contact-dialog-component,
  gallery-dialog-component,
  filter-dialog-component {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.filter-dialog {
  --mat-expansion-header-hover-state-layer-color: transparent;
  --mat-expansion-header-focus-state-layer-color: transparent;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 48px;
  .mdc-dialog__content {
    padding: 0 24px !important;
  }
  .mat-expansion-panel {
    margin-bottom: 32px;
  }
  .mat-expansion-panel-header {
    padding: 0;
  }
  .mat-expansion-panel-body {
    padding: 0 8px;
  }
  app-chips-container {
    display: block;
    margin-bottom: 20px;
  }
}

.sort-expansion {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.submission-confirmed {
  border-bottom: none !important;
}

.ContactForm {
  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .submission-confirmed & {
      padding-bottom: 113px !important;
    }

    .icon-bg {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: map-get($light, primary, tint);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .mat-icon {
        color: map-get($light, primary, main);
      }
    }
  }

  &__header {
    color: map-get($light, text, primary);
    margin-top: 0;
    margin-bottom: 24px;
  }

  &__copy {
    color: map-get($light, text, primary);
    margin-top: 0;
    margin-bottom: 50px;
    text-align: center;
  }

  button {
    @include typography(button-large);
  }
}

.close-button-wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.comparison-modal {
  width: 100%;

  mat-bottom-sheet-container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    border-radius: 0;
  }
}

.gallery-dialog {
  .mdc-dialog__title {
    height: 80px;
    padding: 10px 32px;
  }

  .mdc-dialog__content {
    height: 100%;
    max-height: unset;
    padding: 0 32px !important;
  }

  @include mq(medium) {
    height: calc(100% - 70px) !important;
    max-height: 736px !important;
    max-width: 724px !important;
  }
}