@use 'sass:map';
@import 'src/assets/mixins';
@import 'src/assets/colors';

.Currency {
    &__panel {
        // Can't use CSS variables, panel is out of app-root
        --mat-option-selected-state-label-text-color: $light-black;
        --mdc-list-list-item-label-text-color: $light-black;
    }

    &__option {
        .mat-pseudo-checkbox {
            display: none;
        }
    }

    .mat-mdc-text-field-wrapper {
        border-color: var(--currency-color-override, map-get($light, other, outlined-border));
        width: 102px;
    }

    &--single {
        .mat-mdc-text-field-wrapper {
            border: none;
            width: unset;

            @include mq(large) {
                margin-left: 0;
            }
        }

        .mat-mdc-select-arrow-wrapper {
            display: none;
        }
    }

    .mat-mdc-select-trigger {
        gap: 0;
    }

}